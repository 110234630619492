<template>
  <div class="container">
    <a href="https://jiin.love/">
      <img class="logo" src="/icon/logo-sm.png" alt="모두의지인" />
    </a>
    <h1>동물로 알아보는 <br> 연애스타일🐯</h1>
    <h2>
      내 연애 스타일은?!
    </h2>
    <section class="img">
      <img :src="`/result/intj.png`" alt="intj" />
    </section>
    <Button @confirm="startTest">연애유형 알아보기🎉</Button>
    <Share />
  </div>
</template>

<script>
import Button from "@/components/Button";
import Share from "@/components/Share";
export default {
  name: "Home",
  components: {
    Button,
    Share
  },
  methods: {
    startTest() {
      this.$router.push({
        name: "Test"
      });
    }
  }
};
</script>
<style lang="scss" sceopd>
h2 {
  margin-top: 1.2em;
}
section.img {
  height: auto;
  margin: 1em auto 3em;
  img {
    width: 60%;
  }
}
</style>